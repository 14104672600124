export const DATA_TUTORIAL_VIDEOS = [
  {
    id: 0,
    title: 'Olá, de novo.',
    text: '<p> Agora vamos te explicar um pouco sobre as videoaulas.</p>'
  },
  {
    id: 1,
    title: 'Acesso ao Conteúdo',
    text:
      '<p>Ao acessar um tópico da disciplina, você estará acessando a página de videoaulas e nela você encontrará os conteúdos relacionados a esse tópico, localizado ao lado da videoaula. Além disso, você também poderá navegar pelos outros tópicos da disciplina sem precisar sair da página.</p>',
    imgs: [
      {
        src: 'video.png',
        alt: 'A imagem mostra a página de vídeo'
      }
    ]
  },
  {
    id: 2,
    title: 'Outros acessos',
    text:
      '<p>Além dos conteúdos, você também tem acesso a outras funcionalidades como:</p><br><ul><li><strong>Anotações</strong> e <strong>Comentários</strong> sobre as aulas;</li><li><strong>Enviar uma dúvida</strong> sobre o assunto assistido sem precisar sair da página;</li><li>Acesso aos outros conteúdos do assunto: <strong>Exercícios</strong> e <strong>Materiais.</strong></li></ul>',
    imgs: [
      {
        src: 'otherAccess.png',
        alt: 'A imagem mostra outras opções de acesso na página de vídeo'
      }
    ]
  },
  {
    id: 3,
    title: 'Conheceu nossas Videoaulas?',
    text:
      '<p>Agora que já sabe como que funciona nossas videoaulas. Vamos dar início aos seus estudos?</p>'
  }
];
